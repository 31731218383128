import {Associate} from '@/domain/model/associate';
import {BusinessContact, Contact} from '@/domain/model/types';
import {ContactsRepository} from '@/data/contactsDataRepository';
import {Business} from '@/domain/model/business';
import {ContactPhotoRepository} from '@/data/contactPhotoDataRepository';
import {inject, injectable, singleton} from 'tsyringe';

@injectable()
@singleton()
export class CreateCustomContactUseCase {
    private contactsRepository: ContactsRepository;
    private photoRepository: ContactPhotoRepository;

    constructor(@inject('ContactsRepository') contactsRepository: ContactsRepository,
                @inject('ContactPhotoRepository') photoRepository: ContactPhotoRepository) {
        this.contactsRepository = contactsRepository;
        this.photoRepository = photoRepository;
    }

    public invoke = async (business: Business, associate: Associate, contactData?: Contact): Promise<BusinessContact | null> => {
        const newContact: BusinessContact = {
            associate: {
                id: associate.id,
                fullName: associate.fullName,
                email: associate.email,
                position: associate.position || '',
                phoneNumber: associate.phoneNumber || '',
                photoUrl: associate.photoUrl || null
            },
            business: {id: business.id!, name: business.name},
            name: associate.fullName,
            position: associate.position || '',
            email: associate.email,
            phoneNumber: associate.phoneNumber || '',
            type: 1,
            associateIDs: [associate.id!],
            rules: {
                NOTIFICATION: {
                    notify: true,
                    delay: 0
                },
                VISIBILITY: {
                    visible: false
                },
                CONVERSATION: {
                    type: 1
                }
            },
            flatIndex: 0
        };

        if (!!contactData) {
            newContact.name = contactData.name;
            newContact.email = contactData.email || '';
            newContact.position = contactData.position || '';
            newContact.phoneNumber = contactData.phoneNumber || '';
            if (contactData.useProfilePhotoUrl && !!associate.photoUrl) {
                newContact.photoUrl = associate.photoUrl
            }
        }

        const lastContact = await this.contactsRepository.findLast(business.id!);
        newContact.flatIndex = lastContact ? lastContact.flatIndex! + 1 : 0;

        const contact = await this.contactsRepository.save(newContact);
        if (!!contactData && !!contactData.photoFile) {
            const downloadUrl = await this.photoRepository.save(business.id!, {
                contactId: contact.id,
                file: contactData.photoFile
            });
            console.log('contact photo downloadUrl = ' + downloadUrl);
        }
        return contact;
    };
}
